import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import BackgroundImage from 'gatsby-background-image';
import styles from '../styles/index.module.css';

export const query = graphql`
	{
		indexImage: file(relativePath: { eq: "Circuitry.png" }) {
			childImageSharp {
				fluid(quality: 100) {
					...GatsbyImageSharpFluid
				}
			}
		}
		cms {
			allCourses {
				name
			}
			allArticles(first: 1) {
				title
				posted
			}
		}
	}
`;

export default ({ data }) => (
	<Layout>
		<SEO title='Home' />
		<BackgroundImage fluid={data.indexImage.childImageSharp.fluid}>
			<section id={styles.indexScene}>
				<h2>Our Vision</h2>
				<p>
					Creating a community of engineers who are well qualified to play an important role in the
					development of the electronics industry worldwide.
				</p>
				<h2>Our Mission</h2>
				<p>
					Meeting the needs of undergraduate and postgraduate student interested in the field of electronics
					and all of it&apos;s related topics
				</p>
			</section>
		</BackgroundImage>
	</Layout>
);
